<template>
<div>
  <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Edit Area">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Name"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 50 characters"
                    v-model="form.name"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Description"
                  class="required"
                >
                  <b-form-input
                    placeholder="upto 100 characters"
                    v-model="form.description"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Unit"
                  class="required"
                >
                  <b-form-select v-model="form.unit">
                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="unit.name" v-for="unit in unitsData" :key="unit._id">{{unit.name}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Width"
                  class="required"
                >
                  <b-form-input
                    placeholder=""
                    v-model="form.width"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            

            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Length"
                  class="required"
                >
                  <b-form-input
                    placeholder=""
                    v-model="form.length"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Height"
                  class="required"
                >
                  <b-form-input
                    placeholder=""
                    v-model="form.height"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            
            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name: 'location-areas', params:{site_id: form.site_id, location_id: form.location_id}})"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,
      unitsData: [],
      form :{
        name : '',
        description : '',
        unit:'',
        width:'',
        length:'',
        height:'',
        site_id : '',
        location_id : '',
        id:'',
      }
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-area'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name: 'location-areas', params:{site_id: this.form.site_id, location_id: this.form.location_id}})
                });
                
            }
        });
      
    },

    units(){
      return this.$store.dispatch(GET_API, {
           data:{
             
           },
           api: '/api/units'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                this.unitsData = this.$store.getters.getResults.data;
                
                return this.unitsData;
            }
        });
    },

    areaDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id: this.$route.params.id
           },
           api: '/api/area-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var item = this.$store.getters.getResults.data;
                
                this.form.name  =  item.name;
                this.form.description  =  item.description;
                this.form.unit = item.unit;
                this.form.width = item.width;
                this.form.length = item.length;
                this.form.height = item.height;
                this.form.site_id  =  item.site;
                this.form.location_id  =  item.location;
                this.form.id  =  item._id;

                return this.form;
            }
        });
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to: null, // hyperlink
        text: 'Project Sites' // crumb text
      },
      {
        to: {name:'job-sites'},
        text: 'Sites', // crumb text
      },
      {
        to: {name:'site-locations',params:{site_id:this.form.site_id}}, // hyperlink
        text: 'Locations', // crumb text
        
      },
      {
        to: {name:'location-areas',params:{site_id:this.form.site_id,location_id:this.form.location_id}}, // hyperlink
        text: 'Areas', // crumb text
        
      },{
        to: null, // hyperlink
        text: 'Edit Area', // crumb text
        active:true
      }];
      return item;
    }

  },
  mounted(){
    this.units();
    this.areaDetail();
  }
}
</script>
